jQuery(document).ready(function ($) {

  // open and close select your industry
  $(window).click(function () {
    if ($('.industry-dropdown').hasClass('active')) {
      $('.industry-dropdown ul').slideUp('fast');
      $('.industry-dropdown').removeClass('active');
    }
  });
  $('.industry-dropdown > span').click(function (e) {
    if ($('.industry-dropdown').hasClass('active')) {
      $('.industry-dropdown ul').slideUp('fast');
      $('.industry-dropdown').removeClass('active');
    } else {
      $('.industry-dropdown ul').slideDown('fast');
      $('.industry-dropdown').addClass('active');
    }
    e.stopPropagation();
  });

  // set and clear vimeo video iframe src
  $('.zoom > span').click(function () {
    $('[data-iframe-id="video_217854871"]').attr('src', 'https://player.vimeo.com/video/227092496?autoplay=1&loop=1');
  });

  $('[data-remodal-id="vimeoVideo"] [data-remodal-action="close"]').click(function () {
    $('[data-iframe-id="video_217854871"]').attr('src', '');
  });

  // fade out scroll indicator
  $(window).scroll(function () {
    var scrollTop = $(window).scrollTop();
    var height = $(window).height();

    $('.scroll-indicator').css({
      'opacity': ((height - scrollTop) / height / 2)
    });
  });

  // request a demo
  // $('.demo-form .demo-submit').click(function (event) {
  //   if (!$('.demo-form input[type="email"]').val()) {
  //     event.preventDefault();
  //   } else {
  //     event.preventDefault();
  //     var root = location.protocol + '//' + location.host + '/contact';
  //     var email = $('.demo-form input[type="email"]').val();
  //     var msg = "I'd like to request a demo of LRS Antilles!";
  //     var url = root + '?email=' + email + '&msg=' + msg;
  //     window.location.href = url;
  //   }
  // });
  // $('.demo-form input[type="email"]').on('keyup', function (e) {
  //   var root = location.protocol + '//' + location.host + '/contact';
  //   var email = $('.demo-form input[type="email"]').val();
  //   var msg = "I'd like to request a demo of LRS Antilles!";
  //   var url = root + '?email=' + email + '&msg=' + msg;
  //   if (e.which === 13) {
  //     e.preventDefault();
  //     window.location.href = url;

  //   }
  // });
  // $('.demo-form .demo-submit').on('keyup', function (e) {
  //   if ($(this).is(':focus')) {
  //     var root = location.protocol + '//' + location.host + '/contact';
  //     var email = $('.demo-form input[type="email"]').val();
  //     var msg = "I'd like to request a demo of LRS Antilles!";
  //     var url = root + '?email=' + email + '&msg=' + msg;
  //     if (e.which === 13) {
  //       e.preventDefault();
  //       console.log('focused and pressed');
  //       window.location.href = url;

  //     }
  //   }
  // });

  // load video if not mobile
  var md = new MobileDetect(window.navigator.userAgent);
  if (!md.mobile()) {
    // location.href = (md.mobileGrade() === 'A') ? '/mobile/' : '/lynx/';
    console.log('not mobile');
    $("#video-background").first().attr('src', 'https://player.vimeo.com/external/227092496.hd.mp4?s=85c36e898b8bd124663b27264c165af8cc50a198&profile_id=175&oauth2_token_id=968882605');
  }

});